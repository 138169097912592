import gsap from 'gsap'
import { css, cssBatch, find, findAll } from '../utils/dom'
import SplitType from 'split-type'

new SplitType(find('#intro-text'), { types: 'words' })

console.log(
	findAll('[js-split-words]')
		.map((i) => new SplitType(i, { types: 'words' }))
		.map((split) => {
			split.words.forEach((word, index) => {
				const _index = () => 0.55 + 0.05 * index
				word.setAttribute('js-anima', '')
				word.setAttribute('data-anima', 'right')
				word.setAttribute('data-delay', _index())
				word.setAttribute('data-trigger', '#ingressos')
			})
		})
)

const $ = {
	menu: {
		logo: find('#logo'),
		lis: findAll('#menu-list li'),
		btn: find('#btn-menu'),
	},
	intro: {
		badge: find('#intro-badge'),
		badgeBg: find('#intro-badge .bg'),
		badgeP: find('#intro-badge p'),
		text: findAll('#intro-text .word'),
		data: findAll('#intro-data'),
		traco: findAll('#intro-traco'),
		local: findAll('#intro-local'),
	},
	ingressos: {
		cardCamarote: find('#card-camarote-convencional'),
	},
}
let tl
$.ingressos.cardCamarote.addEventListener('mousemove', (e) => {
	const { x, y, currentTarget } = e
	tl = gsap.to(currentTarget, {
		'--deg': x - y,
		duration: 13,
		ease: 'power3',
	}) //
	gsap.to(currentTarget, { y: -20, duration: 0.4 })
})

$.ingressos.cardCamarote.addEventListener('mouseleave', (e) => {
	console.log('mouseleave')
	const { x, y, currentTarget } = e

	gsap.to(currentTarget, { '--deg': 270, overwrite: true, duration: 0.5, y: 0 })
})

const menuItens = Object.values($.menu).flat()
const hide = (el) => gsap.set(el, { opacity: 0 })
const transitionNone = (items) => items.forEach((i) => (i.style.transitionProperty = 'none'))
const transitionRevert = (items) => items.forEach((i) => i.attributeStyleMap.delete('transition-property'))

function setAnimationBaseIntro() {
	return gsap
		.timeline()
		.call(transitionNone, [menuItens])
		.set(menuItens, { opacity: 0, xPercent: 50 })
		.set($.intro.badgeP, { scale: 0 })
		.set($.intro.badgeBg, { scale: 0 })
		.set([$.intro.data, $.intro.local, $.intro.traco], { opacity: 0, xPercent: 10 })
		.set($.intro.text, { opacity: 0, xPercent: 30 })
}

function toAnimationBaseIntro() {
	return gsap
		.timeline({ delay: 1 })
		.to(menuItens, {
			opacity: 1,
			stagger: 0.15,
			xPercent: 0,
			ease: 'power4.out',
			onComplete: () => transitionRevert(menuItens),
		})
		.to(
			[$.intro.badgeBg, $.intro.badgeP],
			{
				transformOrigin: 'left bottom',
				scale: 1,
				stagger: 0.1,
				duration: 0.3,
				ease: 'power4.out',
			},
			'-=0.4'
		)
		.to(
			$.intro.text,
			{
				opacity: 1,
				stagger: 0.05,
				xPercent: 0,
				ease: 'power4.out',
			},
			'-=0.1'
		)
		.to(
			[$.intro.data, $.intro.local, $.intro.traco],
			{
				opacity: 1,
				stagger: 0.05,
				xPercent: 0,
				ease: 'power4.out',
			},
			'-=0.1'
		)
}

export { setAnimationBaseIntro, toAnimationBaseIntro, hide }
