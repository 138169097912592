import 'css/base/variables.css' // variaveis
import 'css/vendor/tippy-js.css'
import 'tippy.js/animations/shift-away-subtle.css'
import 'css/index.css' // entrada
import { hide, setAnimationBaseIntro, toAnimationBaseIntro } from './js/animation/animationBase'
import animaAoScroll from './js/animation/animaAoScroll'
import { findAll } from './js/utils/dom'

setAnimationBaseIntro()
findAll('[js-anima]:not([dont-hide])').forEach((el) => hide(el))
const dev = import.meta.env.MODE !== 'production'

document.addEventListener('DOMContentLoaded', () => {
	const time = dev ? 1000 : 0
	setTimeout(() => {
		document.body.classList.add('DOMContentLoaded')
		toAnimationBaseIntro()
		animaAoScroll()
	}, time)
})
