import { gsap } from 'gsap'
import { findAll } from '../utils/dom.js'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import animateFrom from './animateFrom.js'

export default function animaAoScroll() {
	const start = 'top bottom-=100'

	findAll('[js-anima]').forEach((elem) => {
		const _trigger = elem.getAttribute('data-trigger')
		const trigger = _trigger ? document.querySelector(_trigger) : elem

		ScrollTrigger.create({
			trigger: trigger,
			once: true,
			start: start,
			refreshPriority: 10,
			// markers: true,
			onEnter: function () {
				animateFrom(elem)
			},
			// onEnterBack: function () {
			// 	animateFrom(elem, -1)
			// },
			// onLeave: function () {
			// 	hide(elem)
			// } // assure that the element is hidden when scrolled into view
		})
	})
}
